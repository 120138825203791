body {
  margin: 0;
  padding: 0;
  background-color: #f5f1ee;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-style: normal;
  zoom: 90% !important;
}

@media (min-width: 1600px) {
  body {
    zoom: 100% !important;
  }
}

.header {
  position: sticky !important;
  top: 0 !important;
  z-index: 1000 !important;
  padding-left: 13rem !important;
  padding-right: 13rem !important;
}

@media (max-width: 768px) {
  .header {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

.header.bg-white {
  background-color: white !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #616161;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #616161;
}

.footer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
}

.bg-black {
  background-color: #616161 !important;
  border: none;
}

.p-icon {
  width: 2rem !important;
  height: 2rem !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #616161;
}

.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled)
  .p-accordion-header-link {
  background-color: #f5f1ee;
  border-color: #000;
  color: #000;
}

.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background-color: #000;
  border-color: #000;
  color: #e7e7e8;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background-color: #000;
  border-color: #000;
  color: #e7e7e8;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link {
  background-color: #000;
  border-color: #000;
  color: #e7e7e8;
}

.p-accordion .p-accordion-content {
  background: #ead5b8;
  color: #000;
}

.p-accordion {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

@media (max-width: 768px) {
  .p-accordion {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.p-accordion-tab {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.background-dark-blue {
  background-color: #071130 !important;
}

.text-dark-blue {
  color: #071130 !important;
}

.background-traffic-white {
  background-color: #e5e5e5 !important;
}

.background-menu {
  background-image: url("../../../public/images/fo-menu.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  z-index: -1;
  width: 100%;
}
.custom-calendar-placeholder::placeholder {
  color: #071130 !important;
  font-weight: bold;
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #071130 !important;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  color: #071130 !important;
}
